import React from "react";
import CounsellorSearchFilters from "../../../types/CounsellorSearchFilters";
import useCounsellorSearchFilters from "../../../pages/dashboard/therapist/useCounsellorSearchFilters";
import {FieldInputProps, FormikErrors} from "formik/dist/types";
import {FormikValues} from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import {TextField} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type Props = {
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
}
export default function ContentModuleFilterTagsDropdown({getFieldProps, setFieldValue}: Props) {
    const allAvailableFilters = useCounsellorSearchFilters();
    const FILTERS_OPTIONS = allAvailableFilters
        ? CounsellorSearchFilters.of(allAvailableFilters).toFilterOptions()
        : null;

    if (!FILTERS_OPTIONS) {
        return null;
    }

    const selectedFilters = FILTERS_OPTIONS?.filter((option) =>
        getFieldProps("filters").value?.includes(option.value)
    ) ?? [];

    return (
        <Autocomplete
            multiple
            id="filter-tags"
            value={selectedFilters}
            onChange={(event, values) => {
                const updatedFilters = values?.map((item) => item.value) ?? [];
                setFieldValue("filters", updatedFilters)
            }}
            options={FILTERS_OPTIONS}
            disableCloseOnSelect
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                        checkedIcon={<CheckBoxIcon fontSize="small"/>}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.label}
                </li>
            )}
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Filter tags"
                    placeholder="Tags to help match the cousellor to users based on their expertise and experience."
                />
            )}
        />)
}
