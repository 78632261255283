import { Icon } from "@iconify/react";
import { ReactNode, useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import viewIcon from "@iconify/icons-ant-design/read-outlined";

// material
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// ----------------------------------------------------------------------

type MoreMenuProps = {
  viewPathTo?: string | any;
  editPathTo?: string | any;
  onDelete?: VoidFunction;
  onUpdate?: VoidFunction;
  updateButtonText?: string;
  updateButtonIconName?: string;
  copyText?: string;
  onEdit?: VoidFunction;
  onView?: (itemIdentifier: string) => void;
  onCopy?: () => void;
  itemIdentifier?: string;
  deleting?: boolean;
  editButtonText?: string;
  deleteButtonText?: string;
  deleteButtonIcon?: ReactNode;
};

export default function MoreMenu({
  onDelete,
  editPathTo,
  viewPathTo,
  onView,
  itemIdentifier,
  onEdit,
  onCopy,
  deleting,
  onUpdate,
  updateButtonText,
  updateButtonIconName,
  copyText,
  editButtonText,
  deleteButtonText,
  deleteButtonIcon,
}: MoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // @ts-ignore
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {Boolean(onView && typeof onView == "function") && (
          <MenuItem
            onClick={() => onView?.(itemIdentifier || "")}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={viewIcon} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="View"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {Boolean(onEdit && typeof onEdit == "function") && (
          <MenuItem onClick={onEdit} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={editButtonText || "Edit"}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {Boolean(onDelete && typeof onDelete == "function") && (
          <MenuItem onClick={onDelete} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              {deleteButtonIcon || (
                <Icon icon={trash2Outline} width={24} height={24} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={deleteButtonText || "Delete"}
              primaryTypographyProps={{ variant: "body2" }}
            />
            {deleting && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  alignSelf: "center",
                  right: 10,
                }}
                size={25}
              />
            )}
          </MenuItem>
        )}

        {Boolean(onUpdate && typeof onUpdate == "function") && (
          <MenuItem onClick={onUpdate} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={updateButtonIconName  || "carbon:tool-kit"} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={updateButtonText || "Update"}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {Boolean(editPathTo) && (
          <MenuItem
            component={RouterLink}
            to={editPathTo}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {Boolean(viewPathTo) && (
          <MenuItem
            component={RouterLink}
            to={viewPathTo}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={viewIcon} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="View"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {Boolean(onCopy && typeof onCopy == "function") && (
          <MenuItem onClick={onCopy} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <ContentCopyIcon />
            </ListItemIcon>
            <ListItemText
              primary={copyText ?? "Copy"}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
