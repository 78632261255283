import { CCRoles } from "../types/role/CCRoles";
import { ThemeKeys } from "react-json-view";
import { SupportedLanguages } from "../types/invitation/code";
import { BotCategory, RatingBotCategory } from "../types/bot-data/botDataType";
import {
  CharacteristicKeyToNameType,
  CharacteristicNameToKeyType,
} from "../types/meditationVoice";

export const ROLES: CCRoles = {
  INV_CODE_READ: "inv_code_r",
  INV_CODE_WRITE: "inv_code_w",
  INV_CODE_MANAGE: "inv_code_m",

  BOT_DATA_READ: "bot_r",
  BOT_DATA_WRITE: "bot_w",
  BOT_DATA_MANAGE: "bot_m",

  ANALYTICS_READ: "anlytcs_r",
  APP_ANALYTICS_READ: "app_anlytcs_r",
  CHAT_READ: "chat_r",
  CHAT_WRITE: "chat_w",
  CHAT_MANAGE: "chat_m",

  THERAPIST_READ: "thrpst_r",
  THERAPIST_WRITE: "thrpst_w",
  THERAPIST_MANAGE: "thrpst_m",

  SURVEY_READ: "srvy_r",
  SURVEY_WRITE: "srvy_w",
  SURVEY_MANAGE: "srvy_m",

  APP_STABLE_VERSION_READ: "stbl_ver_r",
  APP_STABLE_VERSION_WRITE: "stbl_ver_w",
  APP_STABLE_VERSION_MANAGE: "stbl_ver_m",

  ACCOUNT_SETTING_READ: "acc_sett_r",
  ACCOUNT_SETTING_WRITE: "acc_sett_w",
  ACCOUNT_SETTING_MANAGE: "acc_sett_m",

  SETTING_READ: "sett_r",
  SETTING_WRITE: "sett_w",
  SETTING_MANAGE: "sett_m",

  SUPER_ADMIN: "supr_admn",

  // this role is only for testing purposes on DEV only.
  SUPER_TEST_ADMIN: "test-supr-admn",

  CONFERENCE_CALL_READ: "cnfrnc_cl_r",
  CONFERENCE_CALL_WRITE: "cnfrnc_cl_w",
  CONFERENCE_CALL_MANAGE: "cnfrnc_cl_m",

  TRANSLATIONS_READ: "trans_r",
  TRANSLATIONS_WRITE: "trans_w",
  TRANSLATIONS_MANAGE: "trans_m",

  UNRELEASED_THERAPIST_READ: "unreleased_therapist_r",
  UNRELEASED_SEARCH_FEATURE_READ: "unreleased_search_feature_r",

  USERS_CREDITS_READ: "users_credits_r",
  USERS_CREDITS_WRITE: "users_credits_w",
  USERS_CREDITS_MANAGE: "users_credits_m",

  // this role is for users of corporate dashbaord, who can access corporate dashboard.
  CORPORATE_DASHBOARD_READ: "corp_dsh_r",
  CORPORATE_DASHBOARD_MANAGE: "corp_dsh_m",
  CORPORATE_DASHBOARD_SUPER_ADMIN: "corp_dsh_supr_admn",

  // this role is for the invoices
  INVCS_READ: "invcs_r",
  INVCS_WRITE: "invcs_w",
  INVCS_MODIFY: "invcs_m",

  // this role is tp search users
  USER_SEARCH: "user_search",

  // this role is for release notes
  RELEASE_NOTES: "release_notes",

  // this role is for release notes
  PRIVACY_POLICY: "privacy_policy",

  // this role(s) are for app-side translations
  APP_TRANSLATION_CRUD: "app_translation_crud",
  APP_TRANSLATION_EN: "app_translation_en",
  APP_TRANSLATION_ES: "app_translation_es",
  APP_TRANSLATION_DE: "app_translation_de",
  APP_TRANSLATION_FR: "app_translation_fr",
  APP_TRANSLATION_AR: "app_translation_ar",
  APP_TRANSLATION_CS: "app_translation_cs",
  APP_TRANSLATION_DA: "app_translation_da",
  APP_TRANSLATION_EL: "app_translation_el",
  APP_TRANSLATION_FI: "app_translation_fi",
  APP_TRANSLATION_HI: "app_translation_hi",
  APP_TRANSLATION_IT: "app_translation_it",
  APP_TRANSLATION_JA: "app_translation_ja",
  APP_TRANSLATION_MS: "app_translation_ms",
  APP_TRANSLATION_NL: "app_translation_nl",
  APP_TRANSLATION_PL: "app_translation_pl",
  APP_TRANSLATION_PT_BR: "app_translation_pt-br",
  APP_TRANSLATION_RO: "app_translation_ro",
  APP_TRANSLATION_RU: "app_translation_ru",
  APP_TRANSLATION_SK: "app_translation_sk",
  APP_TRANSLATION_SR: "app_translation_sr",
  APP_TRANSLATION_SV: "app_translation_sv",
  APP_TRANSLATION_TR: "app_translation_tr",
  APP_TRANSLATION_UK: "app_translation_uk",
  APP_TRANSLATION_UR: "app_translation_ur",
  APP_TRANSLATION_VI: "app_translation_vi",
  APP_TRANSLATION_ZH: "app_translation_zh",
  APP_TRANSLATION_HU: "app_translation_hu",
  APP_TRANSLATION_NO: "app_translation_no",
  APP_TRANSLATION_KO: "app_translation_ko",
  APP_TRANSLATION_BG: "app_translation_bg",

  // this role is for audio meditation generation
  AUDIO_CRUD: "audio_crud",
  USER_GENERATED_AUDIO_MEDITATION_CRUD: "user_generated_audio_meditation_crud",
  MEDITATION_VOICES_CRUD: "meditation_voices_crud",
  AUDIO_CRUD_EN: "audio_crud_en",
  AUDIO_CRUD_UR: "audio_crud_ur",
  AUDIO_CRUD_AR: "audio_crud_ar",
  AUDIO_CRUD_JA: "audio_crud_ja",
  AUDIO_CRUD_TR: "audio_crud_tr",
  AUDIO_CRUD_DE: "audio_crud_de",
  AUDIO_CRUD_FR: "audio_crud_fr",
  AUDIO_CRUD_ES: "audio_crud_es",
  AUDIO_CRUD_SR: "audio_crud_sr",
  AUDIO_CRUD_ZH: "audio_crud_zh",
  AUDIO_CRUD_RU: "audio_crud_ru",
  AUDIO_CRUD_IT: "audio_crud_it",
  AUDIO_CRUD_PL: "audio_crud_pl",
  AUDIO_CRUD_RO: "audio_crud_ro",
  AUDIO_CRUD_CS: "audio_crud_cs",
  AUDIO_CRUD_VI: "audio_crud_vi",
  AUDIO_CRUD_NL: "audio_crud_nl",
  AUDIO_CRUD_PT_PT: "audio_crud_pt-pt",
  AUDIO_CRUD_PT_BR: "audio_crud_pt-br",
  AUDIO_CRUD_UK: "audio_crud_uk",
  AUDIO_CRUD_HI: "audio_crud_hi",
  AUDIO_CRUD_BN: "audio_crud_bn",
  AUDIO_CRUD_MS: "audio_crud_ms",
  AUDIO_CRUD_ID: "audio_crud_id",
  AUDIO_CRUD_DA: "audio_crud_da",
  AUDIO_CRUD_SV: "audio_crud_sv",
  AUDIO_CRUD_FI: "audio_crud_fi",
  AUDIO_CRUD_NO: "audio_crud_no",
  AUDIO_CRUD_KU: "audio_crud_ku",
  AUDIO_CRUD_HU: "audio_crud_hu",
  AUDIO_CRUD_KO: "audio_crud_ko",
  AUDIO_CRUD_TH: "audio_crud_th",
  AUDIO_CRUD_EL: "audio_crud_el",
  AUDIO_CRUD_SK: "audio_crud_sk",
  AUDIO_CRUD_GSW: "audio_crud_gsw",
  AUDIO_CRUD_BG: "audio_crud_bg",
  AI_PROMPTS_MANAGE: "ai_prompts_m",
  AI_PROMPTS_READ: "ai_prompts_r",
  AI_PROMPTS_WRITE: "ai_prompts_w",
  JSON_KEYS_TRANSLATION_MANAGE: "json_keys_translation_m",

  /* This role is for a user to be able to skip mfa, eg. if they are
  a counsellor and need urgent access to their messages and notes.
  Can also be used for test users to skip mfa.
  */
  SKIP_MFA: "skip_mfa",

  EMAIL_ADMIN: "email_admin",
};

// arr containing roles required to access invitation code side menu & related path
export const INV_CODE_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.INV_CODE_READ,
  ROLES.INV_CODE_WRITE,
  ROLES.INV_CODE_MANAGE,
];

// arr containing roles required to access organisation side menu & related path
export const CORPORATE_DASHBOARD_ROLES = [
  ROLES.CORPORATE_DASHBOARD_READ,
  ROLES.CORPORATE_DASHBOARD_MANAGE,
  ROLES.CORPORATE_DASHBOARD_SUPER_ADMIN,
];

// arr containing roles required to access release notes
export const RELEASE_NOTES_ROLES = [ROLES.RELEASE_NOTES, ROLES.SUPER_ADMIN];

// arr containing roles required to access privacy policy
export const PRIVACY_POLICY_ROLES = [ROLES.PRIVACY_POLICY, ROLES.SUPER_ADMIN];

// arr containing roles required to access therapists side menu & related path
export const THERAPIST_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.THERAPIST_READ,
  ROLES.THERAPIST_WRITE,
  ROLES.THERAPIST_MANAGE,
];

// arr containing roles required to access chat analytics side menu & related path
export const ANALYTICS_ROLES = [ROLES.SUPER_ADMIN, ROLES.ANALYTICS_READ];

export const APP_ANALYTICS_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.APP_ANALYTICS_READ,
];

// arr containing roles required to access surveys side menu & related path
export const SURVEY_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.SURVEY_READ,
  ROLES.SURVEY_WRITE,
  ROLES.SURVEY_MANAGE,
];

// arr containing roles required to access "app stable versions" side menu & related path
export const APP_STABLE_VERSION_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.APP_STABLE_VERSION_MANAGE,
  ROLES.APP_STABLE_VERSION_WRITE,
  ROLES.APP_STABLE_VERSION_READ,
];

// arr containing roles required to access "bot data" & "bot data table" side menus & related path
export const BOT_DATA_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.BOT_DATA_READ,
  ROLES.BOT_DATA_WRITE,
  ROLES.BOT_DATA_MANAGE,
];

export const AI_PROMPT_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.AI_PROMPTS_MANAGE,
  ROLES.AI_PROMPTS_READ,
  ROLES.AI_PROMPTS_WRITE,
];

export const ACC_SETTINGS_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.ACCOUNT_SETTING_READ,
  ROLES.ACCOUNT_SETTING_WRITE,
  ROLES.ACCOUNT_SETTING_MANAGE,
];

export const SETTINGS_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.SETTING_READ,
  ROLES.SETTING_WRITE,
  ROLES.SETTING_MANAGE,
];

export const CONFERENCE_CALL_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.CONFERENCE_CALL_READ,
  ROLES.CONFERENCE_CALL_WRITE,
  ROLES.CONFERENCE_CALL_MANAGE,
];

export const TRANSLATIONS_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.TRANSLATIONS_READ,
  ROLES.TRANSLATIONS_WRITE,
  ROLES.TRANSLATIONS_MANAGE,
];

export const APP_TRANSLATIONS_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.APP_TRANSLATION_CRUD,
  ROLES.APP_TRANSLATION_EN,
  ROLES.APP_TRANSLATION_ES,
  ROLES.APP_TRANSLATION_DE,
  ROLES.APP_TRANSLATION_FR,
  ROLES.APP_TRANSLATION_AR,
  ROLES.APP_TRANSLATION_CS,
  ROLES.APP_TRANSLATION_DA,
  ROLES.APP_TRANSLATION_EL,
  ROLES.APP_TRANSLATION_FI,
  ROLES.APP_TRANSLATION_HI,
  ROLES.APP_TRANSLATION_IT,
  ROLES.APP_TRANSLATION_JA,
  ROLES.APP_TRANSLATION_MS,
  ROLES.APP_TRANSLATION_NL,
  ROLES.APP_TRANSLATION_PL,
  ROLES.APP_TRANSLATION_PT_BR,
  ROLES.APP_TRANSLATION_RO,
  ROLES.APP_TRANSLATION_RU,
  ROLES.APP_TRANSLATION_SK,
  ROLES.APP_TRANSLATION_SR,
  ROLES.APP_TRANSLATION_SV,
  ROLES.APP_TRANSLATION_TR,
  ROLES.APP_TRANSLATION_UK,
  ROLES.APP_TRANSLATION_UR,
  ROLES.APP_TRANSLATION_VI,
  ROLES.APP_TRANSLATION_ZH,
  ROLES.APP_TRANSLATION_HU,
  ROLES.APP_TRANSLATION_KO,
  ROLES.APP_TRANSLATION_NO,
  ROLES.APP_TRANSLATION_BG,
];

export const MULTILINGUAL_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.APP_TRANSLATION_CRUD,
];

// SUPER ADMIN ROLES, HAVE ACCESS TO EVERYTHING.
export const SUPER_ADMIN_ROLES = [ROLES.SUPER_ADMIN];

export const JSON_KEYS_TRANSLATIONS_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.JSON_KEYS_TRANSLATION_MANAGE,
];

export const RESET_MFA_ROLES = [
  ROLES.THERAPIST_MANAGE,
  ROLES.SUPER_ADMIN,
] as const;

export const USERS_CREDITS_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.USERS_CREDITS_READ,
  ROLES.USERS_CREDITS_WRITE,
  ROLES.USERS_CREDITS_MANAGE,
];

export const SEARCH_USER_ROLE = [ROLES.SUPER_ADMIN, ROLES.USER_SEARCH];

export const INVOICES_ROLES = [
  ROLES.INVCS_READ,
  ROLES.INVCS_WRITE,
  ROLES.INVCS_MODIFY,
];

export const AUDIO_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.AUDIO_CRUD,
  ROLES.AUDIO_CRUD_EN,
  ROLES.AUDIO_CRUD_UR,
  ROLES.AUDIO_CRUD_AR,
  ROLES.AUDIO_CRUD_JA,
  ROLES.AUDIO_CRUD_TR,
  ROLES.AUDIO_CRUD_DE,
  ROLES.AUDIO_CRUD_FR,
  ROLES.AUDIO_CRUD_ES,
  ROLES.AUDIO_CRUD_SR,
  ROLES.AUDIO_CRUD_ZH,
  ROLES.AUDIO_CRUD_RU,
  ROLES.AUDIO_CRUD_IT,
  ROLES.AUDIO_CRUD_PL,
  ROLES.AUDIO_CRUD_RO,
  ROLES.AUDIO_CRUD_CS,
  ROLES.AUDIO_CRUD_VI,
  ROLES.AUDIO_CRUD_NL,
  ROLES.AUDIO_CRUD_PT_PT,
  ROLES.AUDIO_CRUD_PT_BR,
  ROLES.AUDIO_CRUD_UK,
  ROLES.AUDIO_CRUD_HI,
  ROLES.AUDIO_CRUD_BN,
  ROLES.AUDIO_CRUD_MS,
  ROLES.AUDIO_CRUD_ID,
  ROLES.AUDIO_CRUD_DA,
  ROLES.AUDIO_CRUD_SV,
  ROLES.AUDIO_CRUD_FI,
  ROLES.AUDIO_CRUD_NO,
  ROLES.AUDIO_CRUD_TH,
  ROLES.AUDIO_CRUD_EL,
  ROLES.AUDIO_CRUD_SK,
  ROLES.AUDIO_CRUD_GSW,
  ROLES.AUDIO_CRUD_BG,
  ROLES.AUDIO_CRUD_HU,
  ROLES.AUDIO_CRUD_KO,
];
export const USER_GENERATED_AUDIO_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.USER_GENERATED_AUDIO_MEDITATION_CRUD,
];
export const MEDITATION_VOICES_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.AUDIO_CRUD,
  ROLES.MEDITATION_VOICES_CRUD,
];


export const EMAIL_ADMIN_ROLES = [ROLES.EMAIL_ADMIN, ROLES.SUPER_ADMIN];

export const collections = {
  C_CLAIM_AUTOSYNC_UTIL: "c_claim_autosync_util",
  THERAPISTS: "therapists",
  INVITATION_CODES: "invitation_codes",
  THERAPISTS_CHANGELOG: "therapists_changelog",
  INVITATION_CODES_CHANGELOG: "invitation_codes_changelog",
  SETTINGS_CHANGELOG: "settings_changelog",
  VERSION_CODE_CHANGELOG: "version_code_changelog",
  SURVEYS_CHANGELOG: "surveys_changelog",
  BOT_DATA_CHANGELOG: "bot_data_changelog",
  CONFERENCE_CALLS: "conference_call_rooms",
  COUNSELLOR_SEARCH_FILTERS: "counsellor_search_filters",
  APP_TRANSLATIONS: "app_translations",
  SUGGESTED_TRANSLATIONS_META_DATA: "suggested_translations_metadata",
  APP_TRANSLATIONS_LANGUAGES: "languages",
  SUGGESTED_TRANSLATIONS_LANGUAGES: "suggested_translations_languages",
  AI_PROMPTS: "ai_prompts",
  SNIPPETS: "snippets",
  TEMPLATES: "templates",
  NOTIFICATIONS_HISTORY: "notifications_history",
  RELEASE_NOTES: "release_notes",
  CHINESE_USER_RELATIONSHIPS: "chinese_user_relationships",
  CHINESE_USER_RELATIONSHIPS_CHANGELOG: "chinese_user_relationships_changelog",
  PAYMENT_LINKS: "payment_links",
  TRANSLATIONS: "translations",
  CONTENT: "content",
  MODULES: "modules",
  PARENT_MODULES: "parent_modules",
  ONBOARDING_EMAILS: "onboarding_emails",
  MULTILINGUAL_LOCALES: "multilingual_locales",
};

export const DEFAULT_LOG_FILTER = `logName=projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/logs/cloudaudit.googleapis.com%2Fdata_access`;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_ORDER_BY = "timestamp desc";
export const logThemeOpts: Array<ThemeKeys> = [
  "apathy",
  "apathy:inverted",
  "ashes",
  "bespin",
  "brewer",
  "bright:inverted",
  "bright",
  "chalk",
  "codeschool",
  "colors",
  "eighties",
  "embers",
  "flat",
  "google",
  "grayscale",
  "grayscale:inverted",
  "greenscreen",
  "harmonic",
  "hopscotch",
  "isotope",
  "marrakesh",
  "mocha",
  "monokai",
  "ocean",
  "paraiso",
  "pop",
  "railscasts",
  "rjv-default",
  "shapeshifter",
  "shapeshifter:inverted",
  "solarized",
  "summerfruit",
  "summerfruit:inverted",
  "threezerotwofour",
  "tomorrow",
  "tube",
  "twilight",
];

export const SETTINGS_ITEM_ID = "emailsNotificationsContent";
export const VERSION_CODE_CHANGELOG = "currentStableVersion";

export enum SupportedLanguagesEnum {
  ENGLISH = "en",
  GERMAN = "de",
  FRENCH = "fr",
  SPANISH = "es",
}

export const LANGUAGES: SupportedLanguages[] = [
  SupportedLanguagesEnum.ENGLISH,
  SupportedLanguagesEnum.SPANISH,
  SupportedLanguagesEnum.FRENCH,
  SupportedLanguagesEnum.GERMAN,
];
export const BOTS: Readonly<Array<BotCategory>> = [
  "Journeys",
  "Meditations",
  "Exercises",
  "Toolkit Exercises",
];

export const BOTS_RATING: Readonly<Array<RatingBotCategory>> = [
  "journeys",
  "exercises",
  "toolkitExercises",
];

export const BOT_DATA_CATEGORIES: Readonly<Array<BotCategory>> = [
  "Journeys",
  "Meditations",
  "Exercises",
  "Toolkit Exercises",
  "Misc",
  "Titles",
  "Sub-Journeys",
  "SleepMeditations",
];

export const EMAILS_TITLES: any = {
  requestAdminEmail: "Therapist requested to Admin Email",
  requestTherapistEmail: "Therapist requested to Therapist Email",
  requestUserEmail: "Therapist requested to User Mail",
  requestCancelledAdminEmail: "Therapist request cancelled to Admin Email",
  requestCancelledTherapistEmail:
    "Therapist requested cancelled to Therapist Email",
  requestCancelledUserEmail: "Therapist requested cancelled to User Email",
  dayBeforeCallAdminEmail: "Day before call Admin Email",
  dayBeforeCallUserEmail: "Day before call User Email",
  counsellorAvailabilityRequest: "COUNSELLORS - AVAILABILITY REQUEST",
  counsellorAvailabilitySent: "COUNSELLORS - AVAILABILITY SENT",
  userAvailabilityRequestSent: "USER - AVAILABILITY REQUEST SENT",
  userAvailabilityReceived: "USER - AVAILABILITY RECEIVED",
  counsellorAppointmentConfirmation: "COUNSELLOR - APPOINTMENT CONFIRMATION",
  counsellorSessionRescheduled: "COUNSELLOR - SESSION RESCHEDULED",
  counsellorSessionCancelled: "COUNSELLOR - SESSION CANCELLED",
  userLiveSessionBooked: "USER - LIVE SESSION BOOKED",
  userSessionCancelled: "USER - SESSION CANCELLED",
  callScheduledInvoiceEmail: "CALL SCHEDULED INVOICE EMAIL",
  callCancelledInvoiceEmail: "CALL CANCELLED INVOICE EMAIL",
  callRequestedInvoiceEmail: "CALL REQUESTED INVOICE EMAIL",
  userUnreadMessage24hrEmail: "USER UNREAD MESSAGE after 20 HOURS EMAIL",
  userUnreadRequest24hrEmail: "USER UNREAD REQUEST after 20 HOURS EMAIL",
  therapistUnreadMessage24hrEmail:
    "COUNSELLOR UNREAD MESSAGE after 20 HOURS EMAIL",
  therapistUnreadRequest24hrEmail:
    "COUNSELLOR UNREAD REQUEST after 20 HOURS EMAIL",
  therapistUnreadMessage36hrEmail:
    "COUNSELLOR UNREAD MESSAGE after 36 HOURS EMAIL",
  therapistUnreadRequest36hrEmail:
    "COUNSELLOR UNREAD REQUEST after 36 HOURS EMAIL",
  therapistExpireAvailability4hrEmail:
    "4 HOURS BEFORE AVAILABILITY EXPIRE COUNSELLOR EMAIL",
  userExpireAvailability4hrEmail:
    "4 HOURS BEFORE AVAILABILITY EXPIRE USER EMAIL",
  userWellBeingAssessmentReminderEmail:
    "USER WELL-BEING ASSESSMENT REMINDER EMAIL",
  userCallReviewEmail: "CALL REVIEW EMAIL",
  streakLostNotificationEmail: "Streak lost notification email",
  tenMinutesBeforeSessionUserReminderEmail:
    "10 minutes before session user reminder email",
  tenMinutesBeforeSessionTherapistReminderEmail:
    "10 minutes before session therapist reminder email",
  sessionStartedUserReminderEmail: "Session started user reminder email",
  sessionStartedTherapistReminderEmail:
    "Session started therapist reminder email",
  tenMinutesAfterSessionUserReminderEmail:
    "10 minutes after session user reminder email",
  tenMinutesAfterSessionTherapistReminderEmail:
    "10 minutes after session therapist reminder email",
  blendedCareRecommendationStatusEmail:
    "Blended Care Recommendation Status Email",
  endCounsellingForUser: "End Counselling for User",
};

export const DefaultTimezone = "Europe/Berlin";

export enum Currency {
  USD = "USD",
  EUR = "EUR",
  CHF = "CHF",
  GBP = "GBP",
}

export const SleepEmotionsWithAnimation = [
  "blueWaves",
  "brownBear",
  "brownEye",
  "brownSun",
  "greenCoffee",
  "greenRainbow",
  "greenTree",
  "greenWaves",
  "lightBlueBear",
  "lightBlueCoffee",
  "lightBlueFocus",
  "lightBlueKites",
  "lightBlueMoon",
  "lightBlueStar",
  "lightBlueSun",
  "lightBlueWaterDrop",
  "mintBear",
  "mintEye",
  "mintFocus",
  "mintKites",
  "mintMoon",
  "mintStar",
  "mintSun",
  "mintWaterDrop",
  "orangeBear",
  "orangeCoffee",
  "orangeSun",
  "pinkRainbow",
  "purpleRainbow",
  "redKites",
  "yellowFocus",
  "yellowMoon",
  "yellowStar",
  "yellowSun",
];

export const SleepEmotions = [
  "moon",
  "star",
  "polygon",
  "ellipse",
  "droplet",
  "deepSleep",
  "tree",
  "waves",
  "blueMoon",
];

export const openAIModels = [
  { value: "gpt-4o", label: "GPT-4o" },
  { value: "gpt-4-32k", label: "GPT-4-32k" },
  { value: "gpt-4-1106-preview", label: "GPT-4-1106" },
];

export enum AIModelType {
  OPEN_AI = "OpenAI",
  ANTHROPIC = "Anthropic Claude",
}
export const AIModelTypes = [AIModelType.OPEN_AI, AIModelType.ANTHROPIC];

export const LANGUAGE_NAMES_TO_CODE: { [key: string]: string } = {
  Arabic: "AR",
  Chinese: "ZH",
  Czech: "CS",
  Dutch: "NL",
  English: "EN",
  French: "FR",
  German: "DE",
  Italian: "IT",
  Japanese: "JA",
  Polish: "PL",
  "Portuguese (Portugal)": "PT-PT",
  "Portuguese (Brazil)": "PT-BR",
  Romanian: "RO",
  Russian: "RU",
  Serbian: "SR",
  Spanish: "ES",
  Turkish: "TR",
  Ukrainian: "UK",
  Urdu: "UR",
  Vietnamese: "VI",
  Hindi: "HI",
  Bengali: "BN",
  Malay: "MS",
  Indonesian: "ID",
  Danish: "DA",
  Swedish: "SV",
  Finnish: "FI",
  Norwegian: "NO",
  Thai: "TH",
  Greek: "EL",
  Slovak: "SK",
  Korean: "KO",
  Kurdish: "KU",
  Hungarian: "HU",
  "Swiss German": "GSW",
  "Bulgarian": "BG",
};

export const LANGUAGE_CODE_TO_NAMES: { [key: string]: string } = {
  EN: "English",
  UR: "Urdu",
  AR: "Arabic",
  JA: "Japanese",
  TR: "Turkish",
  DE: "German",
  FR: "French",
  ES: "Spanish",
  SR: "Serbian",
  ZH: "Chinese",
  RU: "Russian",
  IT: "Italian",
  PL: "Polish",
  RO: "Romanian",
  CS: "Czech",
  VI: "Vietnamese",
  NL: "Dutch",
  "PT-PT": "Portuguese (Portugal)",
  "PT-BR": "Portuguese (Brazil)",
  UK: "Ukrainian",
  HI: "Hindi",
  BN: "Bengali",
  MS: "Malay",
  ID: "Indonesian",
  DA: "Danish",
  SV: "Swedish",
  FI: "Finnish",
  KO: "Korean",
  KU: "Kurdish",
  HU: "Hungarian",
  NO: "Norwegian",
  TH: "Thai",
  EL: "Greek",
  SK: "Slovak",
  GSW: "Swiss German",
  BG: "Bulgarian"
};

export const CHARACTERISTICS_NAME_TO_KEY: CharacteristicKeyToNameType = {
  "meditationVoiceCharacteristics.calm": "Calm",
  "meditationVoiceCharacteristics.deep": "Deep",
  "meditationVoiceCharacteristics.soft": "Soft",
  "meditationVoiceCharacteristics.melodic": "Melodic",
  "meditationVoiceCharacteristics.clear": "Clear",
  "meditationVoiceCharacteristics.warm": "Warm",
  "meditationVoiceCharacteristics.neutral": "Neutral",
  "meditationVoiceCharacteristics.slowPaced": "Slow-Paced",
  "meditationVoiceCharacteristics.rhythmic": "Rythmic",
  "meditationVoiceCharacteristics.authoritative": "Authoritative",
  "meditationVoiceCharacteristics.whispery": "Whispery",
  "meditationVoiceCharacteristics.expressive": "Expressive",
  "meditationVoiceCharacteristics.friendly": "Friendly",
  "meditationVoiceCharacteristics.engergetic": "Energetic",
  "meditationVoiceCharacteristics.young": "Young",
  "meditationVoiceCharacteristics.instructive": "Instructive",
  "meditationVoiceCharacteristics.positive": "Positive",
  "meditationVoiceCharacteristics.pleasant": "Pleasant",
  "meditationVoiceCharacteristics.gentle": "Gentle",
  "meditationVoiceCharacteristics.relaxing": "Relaxing",
  "meditationVoiceCharacteristics.wise": "Wise",
};

export const CHARACTERISTICS_KEY_TO_NAME: CharacteristicNameToKeyType = {
  Calm: "meditationVoiceCharacteristics.calm",
  Deep: "meditationVoiceCharacteristics.deep",
  Soft: "meditationVoiceCharacteristics.soft",
  Melodic: "meditationVoiceCharacteristics.melodic",
  Clear: "meditationVoiceCharacteristics.clear",
  Warm: "meditationVoiceCharacteristics.warm",
  Neutral: "meditationVoiceCharacteristics.neutral",
  "Slow-Paced": "meditationVoiceCharacteristics.slowPaced",
  Rythmic: "meditationVoiceCharacteristics.rhythmic",
  Authoritative: "meditationVoiceCharacteristics.authoritative",
  Whispery: "meditationVoiceCharacteristics.whispery",
  Expressive: "meditationVoiceCharacteristics.expressive",
  Friendly: "meditationVoiceCharacteristics.friendly",
  Energetic: "meditationVoiceCharacteristics.engergetic",
  Young: "meditationVoiceCharacteristics.young",
  Instructive: "meditationVoiceCharacteristics.instructive",
  Positive: "meditationVoiceCharacteristics.positive",
  Pleasant: "meditationVoiceCharacteristics.pleasant",
  Gentle: "meditationVoiceCharacteristics.gentle",
  Relaxing: "meditationVoiceCharacteristics.relaxing",
  Wise: "meditationVoiceCharacteristics.wise",
};

export const OpenAIMessageFunctionTemplate = {
  name: "message",
  // eslint-disable-next-line max-len
  description:
    "Generates a structured response with quick button options for communication in the chat interface. This function also tracks the start and end of exercises as part of user interactions. Use this function to recommend meditations and to log the progress of exercises.",
  parameters: {
    type: "object",
    properties: {
      message: {
        type: "string",
        description: "The primary message to be displayed.",
      },
      meditation_title: {
        type: "string",
        description:
          "Title of the meditation, used when recommending a meditation.",
      },
      uri: {
        type: "string",
        description: "Audio URL of the recommended meditation.",
      },
      options: {
        type: "array",
        items: {
          type: "string",
        },
        description:
          "List of options for quick response. Limit the number to maintain clarity. Any time you recommend a meditation add an option named 'Done'",
      },
      bot_progress: {
        type: "object",
        properties: {
          step: {
            type: "string",
            description:
              "Submit 'Practice-Start' when an exercise is started, and 'Practice-End' when completed. this applies any time a user starts an exercise like BC-1-P1",
          },
          codeName: {
            type: "string",
            description: "Human readable name of the exercise.",
          },
          moduleName: {
            type: "string",
            description: "The name of the exercise, e.g., DCA-1-P2.",
          },
        },
        required: ["moduleName", "step", "codeName"],
        description:
          "Track the progress of the user in an exercise. Call this whenever an exercise starts or ends.",
      },
      add_to_toolkit: {
        type: "object",
        description:
          "whenever a user decides to save an exercise to the toolkit",
        properties: {
          moduleName: {
            type: "string",
            description:
              "the Name of exercise that the user wants to save to the toolkit to for example DCA-1-P2 or DCA-1-P4",
          },
        },
        required: ["moduleName"],
      },
      potential_crisis: {
        type: "boolean",
        description:
          " If the user indicates they are going through an emergency, are currently in crisis, or give any indication of self-harm and suicidal thoughts you call this function",
      },
      talk_to_counsellor: {
        description:
          " If the user indicates they are want to talk to a Kyan Counsellor",
        type: "boolean",
      },
      close_the_bot: {
        description:
          "Whenever the user says goodbye, asks to close the chat or it is clear that it doesn't want to continue call this function",
        type: "object",
        properties: {
          moduleName: {
            type: "string",
            description:
              "the Name of exercise that the user was last working on for example DCA-1-P2",
          },
        },
        required: ["moduleName"],
      },
    },
    required: ["message"],
  },
};


export const MeditationBackgroundVoices =
    [
      {
        value: "Rain",
        key: "48401d9b-017f-4b2e-8231-294c96b081bd",
        sampleAudioUrl:
            "https://storage.googleapis.com/mp3content/Sleep/rainkyan.mp3",
      },
      {
        value: "Forest",
        key: "9aa226fa-d9c6-4c08-a7ba-7fd1e45c74ea",
        sampleAudioUrl:
            "https://storage.googleapis.com/mp3content/Sleep/forestkyan.mp3",
      },
      {
        value: "Waves",
        key: "58d59698-0bce-49af-817e-4c5528c263e1",
        sampleAudioUrl:
            "https://storage.googleapis.com/mp3content/Sleep/gentlewaveskyan.mp3",
      },
      {
        value: "Music",
        key: "94baa74d-de54-456d-b269-8131fe098e2e",
        sampleAudioUrl:
            "https://storage.googleapis.com/mp3content/Sleep/bg-music-1.mp3",
      },
    ];

export const LocaleList = [
  { locale: "en", title: "English" },
  { locale: "de", title: "German" },
  { locale: "fr", title: "French" },
  { locale: "es", title: "Spanish" },
  { locale: "it", title: "Italian" },
  { locale: "pl", title: "Polish" },
  { locale: "pt", title: "Portuguese" },
  { locale: "pt-BR", title: "Portuguese (Brazil)" },
  { locale: "ro", title: "Romanian" },
  { locale: "zh", title: "Chinese" },
  { locale: "ar", title: "Arabic" },
  { locale: "cs", title: "Czech" },
  { locale: "el", title: "Greek" },
  { locale: "fi", title: "Finnish" },
  { locale: "hi", title: "Hindi" },
  { locale: "ja", title: "Japanese" },
  { locale: "ms", title: "Malay" },
  { locale: "nl", title: "Dutch" },
  { locale: "ru", title: "Russian" },
  { locale: "sr", title: "Serbian" },
  { locale: "sk", title: "Slovak" },
  { locale: "sv", title: "Swedish" },
  { locale: "tr", title: "Turkish" },
  { locale: "uk", title: "Ukrainian" },
  { locale: "vi", title: "Vietnamese" },
  { locale: "ur", title: "Urdu" },
  { locale: "da", title: "Danish" },
  { locale: "hu", title: "Hungarian" },
  { locale: "no", title: "Norwegian" },
  { locale: "ko", title: "Korean" },
  { locale: "bg", title: "Bulgarian" },
];
